import NumberFormat, { NumberFormatValues } from "react-number-format";
import { ReactNode } from "react";
import { Label } from "../../components/Label/Label";
import { ColorEnum } from "../../enums/ColorEnum";
import styles from "./MoneyInput.module.scss";

type MoneyInputProps = {
  label?: string;
  value: number;
  onValueChange?: (value: number) => void;
  bordered?: boolean;
  alignRight?: boolean;
  disabled?: boolean;
  boxColor?: ColorEnum;
  tooltipContent?: ReactNode;
};

const MoneyInput = ({
  label,
  value,
  onValueChange,
  bordered,
  alignRight,
  disabled,
  boxColor,
  tooltipContent,
}: MoneyInputProps) => {
  const inputColorClassName = boxColor ? `InputColor-${boxColor}` : "";
  const alignRightClassName = alignRight ? "text-right" : "";
  const className = `${
    inputColorClassName ? styles[inputColorClassName] : ""
  } ${alignRightClassName}`;

  return (
    <div className={styles.Wrapper}>
      <div className={bordered ? styles.MoneyInputBordered : styles.MoneyInput}>
        <Label
          id="money-input-label"
          label={label}
          tooltipContent={tooltipContent}
        />
        <NumberFormat
          className={className}
          data-testid="money-input"
          disabled={disabled}
          value={value}
          decimalScale={2}
          thousandSeparator="."
          decimalSeparator=","
          allowNegative={false}
          allowLeadingZeros={false}
          prefix="€ "
          fixedDecimalScale
          onValueChange={(values: NumberFormatValues) => {
            if (onValueChange) {
              onValueChange(values.floatValue ?? 0);
            }
          }}
        />
      </div>
    </div>
  );
};

export default MoneyInput;
