import { FunctionComponent } from "react";
import { IResizableIcon } from "./ResizableIcon/IResizableIcon";
import ResizableIcon from "./ResizableIcon/ResizableIcon";
import styles from "./Loader.module.scss";

const Loader: FunctionComponent<IResizableIcon> = (props) => {
  return (
    <ResizableIcon width={props.width} height={props.height}>
      <svg
        viewBox="0 0 111 111"
        {...props}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.Loader}
      >
        <path
          d="M55.4999 2.58325V23.7499M55.4999 87.2499V108.417M18.0878 18.0878L33.0633 33.0633M77.9366 77.9366L92.912 92.912M2.58325 55.4999H23.7499M87.2499 55.4999H108.417M18.0878 92.912L33.0633 77.9366M77.9366 33.0633L92.912 18.0878"
          stroke="#1E1E1E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ResizableIcon>
  );
};

export default Loader;
