import { ReactNode } from "react";
import { Tooltip } from "../../../components/Tooltip/Tooltip";

type InformationIconProps = {
  className?: string;
  tooltipContent: ReactNode;
};

export const InformationIcon = ({
  className,
  tooltipContent,
}: InformationIconProps) => {
  return (
    <Tooltip content={tooltipContent}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
      >
        <circle cx="12" cy="12" r="10" />
        <path d="M12 16v-4" />
        <path d="M12 8h.01" />
      </svg>
    </Tooltip>
  );
};
