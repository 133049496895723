import { useState } from "react";
import { ColorEnum } from "../enums/ColorEnum";

import styles from "./useButtonAnimation.module.scss";

export const useButtonAnimation = (primaryColor: ColorEnum) => {
  const [animation, setAnimation] = useState<number>(0);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const animationEnd = () => setAnimation(0);
  const animationStart = () => setAnimation(1);

  const buttonStyle = styles[`AnimatedButton-${primaryColor}`];
  const buttonStyleDisabled = styles.AnimatedButtonDisabled;
  const buttonStyleHidden = styles.AnimatedButtonHidden;

  return {
    anim: animation,
    animationEnd,
    animationStart,
    buttonStyle,
    buttonStyleDisabled,
    buttonStyleHidden,
    buttonDisabled,
    setButtonDisabled,
  };
};
