import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryFunctionContext,
} from "react-query";
import client from "../../api/client";
import { DepositConfiguration } from "../../types/DepositConfiguration";
import { IPostDeposit } from "./IPostDeposit";

const getDepositConfiguration = async () => {
  const result = await client.get(`Deposit/DepositConfiguration`);

  return result.data;
};

const getIsAnyDepositInSameMonthQueryKey = (accountId: number) => {
  return ["accountId", accountId];
};

const getIsAnyDepositInSameMonth = async ({
  queryKey,
}: QueryFunctionContext) => {
  const [, accountId] = queryKey as ReturnType<
    typeof getIsAnyDepositInSameMonthQueryKey
  >;
  const result = await client.get(
    `Deposit/IsAnyDepositInSameMonth?accountId=${accountId}`
  );

  return result.data;
};

const postDeposit = async (depositData: IPostDeposit) => {
  const form = new FormData();
  form.append("accountId", depositData.accountId.toString());
  form.append("amountInCents", depositData.amountInCents.toString());
  if (depositData.sourceOfFunds) {
    form.append("sourceOfFunds", depositData.sourceOfFunds);
  }

  if (depositData.supportingDocument) {
    form.append("supportingDocument", depositData.supportingDocument);
  }

  const result = await client.post<string>(`Deposit`, form);

  return result.data;
};

const getDepositIsPaidQueryKey = (depositId: number) => {
  return ["depositId", depositId];
};

const getDepositIsPaid = async ({ queryKey }: QueryFunctionContext) => {
  const [, depositId] = queryKey as ReturnType<typeof getDepositIsPaidQueryKey>;
  const result = await client.get(
    `Deposit/DepositIsPaid?depositId=${depositId}`
  );

  return result.data;
};

export const useDepositQuery = (accountId: number) => {
  const queryClient = useQueryClient();

  const {
    isLoading: configIsLoading,
    error: configError,
    data: configData,
    refetch: configRefetch,
  } = useQuery<DepositConfiguration, Error>(
    "depositConfiguration",
    getDepositConfiguration
  );

  const {
    isLoading: anyDepositInSameMonthIsLoading,
    error: anyDepositInSameMonthError,
    data: anyDepositInSameMonthData,
    refetch: anyDepositInSameMonthRefetch,
  } = useQuery<boolean, Error>(
    getIsAnyDepositInSameMonthQueryKey(accountId),
    getIsAnyDepositInSameMonth
  );

  const postDepositData = useMutation(
    (depositData: IPostDeposit) => {
      return postDeposit(depositData);
    },
    {
      onError: (e: Error) => {
        console.error(e.message);
      },
      onSuccess: () => {
        queryClient.invalidateQueries("postDepositData");
      },
    }
  );

  return {
    postDepositData,
    configIsLoading,
    configError,
    configData,
    configRefetch,
    anyDepositInSameMonthIsLoading,
    anyDepositInSameMonthError,
    anyDepositInSameMonthData,
    anyDepositInSameMonthRefetch,
  };
};

export const useDepositIsPaidQuery = (depositId: number, enabled: boolean) => {
  const {
    isLoading: isLoading,
    error: error,
    data: data,
    refetch: refetch,
  } = useQuery<string, Error>(
    getDepositIsPaidQueryKey(depositId),
    getDepositIsPaid,
    {
      enabled: enabled,
    }
  );

  return {
    isLoading,
    error,
    data,
    refetch,
  };
};
