import { ReactNode } from "react";
import { InformationIcon } from "../../features/Icons/InformationIcon/InformationIcon";

import styles from "./Label.module.scss";

type LabelProps = {
  id?: string;
  label?: string;
  tooltipContent?: ReactNode;
};

export const Label = ({ id, label, tooltipContent }: LabelProps) => {
  if (!label) return null;

  return (
    <div className={styles.Container}>
      <label data-testid={id} className={styles.Label}>
        {label}
      </label>
      {tooltipContent && <InformationIcon tooltipContent={tooltipContent} />}
    </div>
  );
};
