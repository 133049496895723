import { ReactNode, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "../../Icons/Loader";
import Success from "../../Icons/Success";
import ExclamationMark from "../../Icons/ExclamationMark";
import styles from "./DepositPopup.module.scss";
import { useTranslation } from "react-i18next";
import { useButtonAnimation } from "../../../hooks/useButtonAnimation";
import { ColorEnum } from "../../../enums/ColorEnum";

export enum DepositPopupTypeEnum {
  Loading = "Loading",
  Success = "Success",
  Error = "Error",
}

interface DepositPopupOptions {
  type?: DepositPopupTypeEnum;
  continueButton: boolean;
  closeButton: boolean;
  closeAction?: () => void;
}

type DepositPopup = {
  children?: ReactNode;
  options: DepositPopupOptions;
};

export const DepositPopup = ({ children, options }: DepositPopup) => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState<boolean>(true);
  const iconWidth = "5rem";

  const { anim, animationStart, animationEnd, buttonStyle } =
    useButtonAnimation(ColorEnum.Pink);

  const close = () => {
    animationStart();
    setShowPopup(false);

    if (options.closeAction) {
      options.closeAction();
    }
  };

  const renderIcon = () => {
    if (!options.type) {
      return null;
    } else if (options.type == DepositPopupTypeEnum.Loading) {
      return <Loader width={iconWidth} height={iconWidth}></Loader>;
    } else if (options.type == DepositPopupTypeEnum.Success) {
      return <Success width={iconWidth} height={iconWidth}></Success>;
    } else if (options.type == DepositPopupTypeEnum.Error) {
      return (
        <ExclamationMark width={iconWidth} height={iconWidth}></ExclamationMark>
      );
    }
  };

  const renderContinue = () => {
    if (options.continueButton) {
      return (
        <button
          onClick={() => close()}
          onAnimationEnd={() => animationEnd()}
          className={`${buttonStyle} ${styles.DepositPopupContinue}`}
          anim={anim}
        >
          {t("Deposits.DepositSubmitContinue")}
        </button>
      );
    }
  };

  const renderHeader = () => {
    if (options.closeButton) {
      return (
        <Modal.Header
          closeButton
          className={`border-0 ${styles.DepositPopupClose}`}
        ></Modal.Header>
      );
    }
  };

  return (
    <Modal
      show={showPopup}
      size="lg"
      centered
      onHide={options.continueButton || options.closeButton ? close : null}
      className={styles.DepositPopup}
    >
      {renderHeader()}
      <Modal.Body>
        <div className={styles.DepositPopupIcon}>{renderIcon()}</div>
        <div className={styles.DepositPopupContent}>{children}</div>
        {renderContinue()}
      </Modal.Body>
    </Modal>
  );
};
