import { ReactNode, useState } from "react";
import { usePopper } from "react-popper";
import styles from "./Tooltip.module.scss";

type TooltipProps = {
  content: ReactNode;
  children: ReactNode;
};

export const Tooltip = ({ content, children }: TooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipRef, setTooltipRef] = useState<HTMLSpanElement | null>(null);
  const [contentRef, setContentRef] = useState<HTMLSpanElement | null>(null);

  const {
    styles: popperStyles,
    attributes,
    update,
  } = usePopper(contentRef, tooltipRef, {
    placement: "top",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: { offset: [0, -24] },
      },
    ],
  });

  const openTooltip = () => {
    update?.();
    if (content) {
      setShowTooltip(true);
    }
  };

  const closeTooltip = () => setShowTooltip(false);

  return (
    <span
      ref={setContentRef}
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
    >
      {showTooltip && (
        <div
          ref={setTooltipRef}
          className={styles.Container}
          style={popperStyles["popper"]}
          {...attributes["popper"]}
        >
          <div className={styles.Tooltip}>{content}</div>
        </div>
      )}
      {children}
    </span>
  );
};
