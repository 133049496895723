import { ReactNode } from "react";
import styles from "./Link.module.scss";

type ExternalLinkProps = {
  href: string;
  children?: ReactNode;
  color?: string;
};

export const ExternalLink = ({ children, href, color }: ExternalLinkProps) => {
  return (
    <a
      className={styles.Link}
      style={{ color }}
      href={href}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </a>
  );
};
