import { FunctionComponent } from "react";
import { IResizableIcon } from "./ResizableIcon/IResizableIcon";
import ResizableIcon from "./ResizableIcon/ResizableIcon";

const Success: FunctionComponent<IResizableIcon> = (props) => {
  return (
    <ResizableIcon width={props.width} height={props.height}>
      <svg
        viewBox="0 0 97 69"
        {...props}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M94.8332 2.75L31.1248 66.4583L2.1665 37.5"
          stroke="#1E1E1E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ResizableIcon>
  );
};

export default Success;
