import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { ExternalLink } from "../../../components/Link/ExternalLink";
import { useDepositIsPaidQuery } from "../../../contexts/DepositContext/useDepositQuery";
import {
  DepositPopup,
  DepositPopupTypeEnum,
} from "../DepositPopup/DepositPopup";

const depositSuccessIdentifier = "depositSuccess";
const depositIdentifier = "depositId";

export const checkDepositSubmitCallback = () => {
  if (window.location.href.includes(depositSuccessIdentifier)) {
    const params = new URL(window.location.href).searchParams;
    const paramValue = params.get(depositSuccessIdentifier);
    const paramIdValue = params.get(depositIdentifier);

    window.localStorage.setItem(
      depositSuccessIdentifier,
      paramValue ?? "False"
    );
    window.localStorage.setItem(depositIdentifier, paramIdValue ?? "");
  }
};

export const DepositSubmitCallback = () => {
  const { t } = useTranslation();
  const value = window.localStorage.getItem(depositSuccessIdentifier);
  const id = window.localStorage.getItem(depositIdentifier);
  const [paymentStatusSuccess, setPaymentStatusSuccess] = useState<
    boolean | undefined
  >(undefined);
  const [skipLoading, setSkipLoading] = useState<boolean>(false);
  const { data, error, isLoading } = useDepositIsPaidQuery(
    Number(id) ?? 0,
    value != null
  );

  useEffect(() => {
    if (error || data === "NotPaid") {
      setPaymentStatusSuccess(false);
    } else if (data === "Paid") {
      setPaymentStatusSuccess(true);
    } else if (!isLoading || data === "Open") {
      setSkipLoading(true);
    }
  }, [data, error, isLoading]);

  if (!value) {
    return null;
  }

  let type = DepositPopupTypeEnum.Error;
  let content = <p>{t("Deposits.DepositSubmitError")}</p>;
  let continueButton = true;

  if (value == "True" && paymentStatusSuccess) {
    type = DepositPopupTypeEnum.Success;
    content = (
      <p>
        <Trans
          i18nKey="Deposits.DepositSubmitSuccess"
          components={{
            tradeDateLink: (
              <ExternalLink href="https://brightpensioen.nl/belangrijke-data/" />
            ),
          }}
        />
      </p>
    );
  } else if (value == "True" && paymentStatusSuccess == undefined) {
    type = DepositPopupTypeEnum.Loading;
    continueButton = skipLoading;
    content = <p>{t("Deposits.DepositSubmitWaiting")}</p>;
  }

  const resetSubmitCallback = () => {
    window.localStorage.removeItem(depositSuccessIdentifier);
    window.localStorage.removeItem(depositIdentifier);
  };

  return (
    <DepositPopup
      options={{
        type: type,
        continueButton: continueButton,
        closeButton: continueButton,
        closeAction: resetSubmitCallback,
      }}
    >
      {content}
    </DepositPopup>
  );
};
