import { FunctionComponent } from "react";
import { IResizableIcon } from "./ResizableIcon/IResizableIcon";
import ResizableIcon from "./ResizableIcon/ResizableIcon";

const ExclamationMark: FunctionComponent<IResizableIcon> = (props) => {
  return (
    <ResizableIcon width={props.width} height={props.height}>
      <svg
        viewBox="0 0 110 110"
        {...props}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M55 34V55M55 76H55.0525M107.5 55C107.5 83.995 83.995 107.5 55 107.5C26.005 107.5 2.5 83.995 2.5 55C2.5 26.005 26.005 2.5 55 2.5C83.995 2.5 107.5 26.005 107.5 55Z"
          stroke="#1E1E1E"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ResizableIcon>
  );
};

export default ExclamationMark;
